<template>
  <q-layout view="hhh lpR fFf" container>
    <q-page-container>
      <q-page>
        <q-scroll-area class="absolute-full mask-overflow-b-drawer">
          <q-tab-panels v-model="tab" animated>
            <q-tab-panel name="cv">
              <cv-items />
            </q-tab-panel>
            <q-tab-panel name="contact">
              <q-list dense padding>
                <q-item class="text-caption" header>{{
                  t("contact.cell")
                }}</q-item>
                <q-item class="text-caption" header>chris@quezada.work</q-item>
              </q-list>
            </q-tab-panel>
          </q-tab-panels>
        </q-scroll-area>
      </q-page>
    </q-page-container>
    <q-footer class="transparent">
      <q-tabs
        v-model="tab"
        active-color="primary"
        class="text-white"
        indicator-color="primary"
        no-caps
        switch-indicator
      >
        <q-tab name="cv" label="CV" />
        <q-tab name="contact" label="Contact" />
      </q-tabs>
      <div class="q-mb-md q-mx-lg">
        <span class="text-primary text-weight-bold">{{ t("name") }}</span> /
        <br />{{ t("title") }}
      </div>
    </q-footer>
  </q-layout>
</template>

<script setup>
import { ref } from "vue";
import { i18n } from "src/boot/i18n";
import CvItems from "../cv/CvItems.vue";
// import WorkItems from "../work/WorkItems.vue";

defineOptions({ name: "DrawerTabs" });

const { t } = i18n.global;
const tab = ref("cv");
</script>
